import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Balance 4×4`}</p>
    <p>{`Hang Squat Snatch 4×4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 rounds for time of:`}</p>
    <p>{`10-Power Snatch (95/65)`}</p>
    <p>{`200 Ft Shuttle Run (in 50ft increments)`}</p>
    <p>{`10-OHS (95/65)`}</p>
    <p>{`10-Burpees Over Bar`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday & Sunday, November 17th-18th, CrossFit the Ville will
be hosting a USAW Weightlifting Level 1 Coaching Certification Course!
 The Scope and Sequence of this course includes the teaching
progressions of the Snatch, Clean & Jerk, and all associated movements.
 Participants will gain knowledge and experience of programming of
training for both competitive weightlifters and for athletes using the
weightlifting movements for strength and power development as it applies
to their chosen sport.  `}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`***`}</strong>{`The gym will be closed for this event.  Saturday is a
scheduled Rest Day but we will make up Sunday’s WOD the following week
by eliminating the next Rest Day to get back on track.`}{`*`}{`*`}{`***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Click the link below to register: `}</em></strong></p>
    <p><a parentName="p" {...{
        "href": "https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977"
      }}>{`https://webpoint.usaweightlifting.org/wp15/Events2/Registrations/Register.wp?rgs_EventID=168977`}</a></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Our Annual Thanksgiving Day wod will be at 9:00am next Thursday!
 This workout is free to all so bring a friend!  This will be the only
class of the day. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      